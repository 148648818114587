import request from '@/axios'
import type { UserLoginType } from './types'

interface RoleParams {
  roleName: string
}

export const loginApi = async (data: UserLoginType): Promise<IResponse> => {
  const res = await request.post({ url: 'api/auth/normal_login', data })
  return res
}

export const logoutApi = (): Promise<IResponse> => {
  return request.post({ url: 'api/logout' })
}

export const getUserApi = (): Promise<IResponse> => {
  return request.get({ url: 'api/member_info' })
}

// https://shopstore-analysizeapidev.openinfo.info/api/member_info

// https://shopstore-analysizeapidev.openinfo.info/api/logout

// export const loginApi = (data: UserType): Promise<IResponse<UserType>> => {
//   return request.post({ url: '/mock/user/login', data })
// }

// export const loginOutApi = (): Promise<IResponse> => {
//   return request.get({ url: '/mock/user/loginOut' })
// }

export const getAdminRoleApi = (params: RoleParams): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/mock/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2', params })
}
