import { useI18n } from '@/hooks/web/useI18n'
import { Layout } from '@/utils/routerHelper'
import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/dashbaord',
    name: 'Dashbaord',
    meta: {
      title: '首頁',
      icon: 'vi-ant-design:dashboard-filled'
    },
    children: [
      {
        path: '/Analysis',
        name: 'Analysis',
        meta: {
          title: '分析頁',
          icon: ''
        },
        children: []
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: '新聞',
      icon: ''
    },
    children: [
      {
        path: '/Search',
        name: 'Search',
        meta: {
          title: '新聞搜索',
          icon: ''
        },
        children: []
      },
      {
        path: '/Monitor',
        name: 'Monitor',
        meta: {
          title: '新聞監控',
          icon: ''
        },
        children: []
      },
      {
        path: '/AIanalysis',
        name: 'AIanalysis',
        meta: {
          title: '案例分析',
          icon: ''
        },
        children: []
      }
    ]
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      title: '個人設定',
      icon: ''
    },
    children: [
      {
        path: '/Account',
        name: 'Account',
        meta: {
          title: '帳號管理',
          icon: ''
        },
        children: []
      },
      {
        path: '/Permission',
        name: 'Permission',
        meta: {
          title: '權限管理',
          icon: ''
        },
        children: []
      },
      {
        path: '/Operation',
        name: 'Operation',
        meta: {
          title: '操作管理',
          icon: ''
        },
        children: []
      }
    ]
  },
  {
    path: '/:path(.*)*',
    redirect: '/404',
    name: '404Page',
    meta: {
      hidden: true,
      breadcrumb: false
    }
  }
]

// export const constantRouterMap: AppRouteRecordRaw[] = [
//   {
//     path: '/',
//     component: Layout,
//     redirect: '/level',
//     name: 'Root',
//     meta: {
//       hidden: true
//     }
//   },
//   {
//     path: '/redirect',
//     component: Layout,
//     name: 'Redirect',
//     children: [
//       {
//         path: '/redirect/:path(.*)',
//         name: 'Redirect',
//         component: () => import('@/views/Redirect/Redirect.vue'),
//         meta: {}
//       }
//     ],
//     meta: {
//       hidden: true,
//       noTagsView: true
//     }
//   },
//   {
//     path: '/login',
//     component: () => import('@/views/Login/Login.vue'),
//     name: 'Login',
//     meta: {
//       hidden: true,
//       title: t('router.login'),
//       noTagsView: true
//     }
//   },
//   {
//     path: '/404',
//     component: () => import('@/views/Error/404.vue'),
//     name: 'NoFind',
//     meta: {
//       hidden: true,
//       title: '404',
//       noTagsView: true
//     }
//   }
// ]

// export const asyncRouterMap: AppRouteRecordRaw[] = [
//   {
//     path: '/level',
//     component: Layout,
//     redirect: '/level/menu1/menu1-1/menu1-1-1',
//     name: 'Level',
//     meta: {
//       title: t('router.level'),
//       icon: 'carbon:skill-level-advanced'
//     },
//     children: [
//       {
//         path: 'menu1',
//         name: 'Menu1',
//         component: getParentLayout(),
//         redirect: '/level/menu1/menu1-1/menu1-1-1',
//         meta: {
//           title: t('router.menu1')
//         },
//         children: [
//           {
//             path: 'menu1-1',
//             name: 'Menu11',
//             component: getParentLayout(),
//             redirect: '/level/menu1/menu1-1/menu1-1-1',
//             meta: {
//               title: t('router.menu11'),
//               alwaysShow: true
//             },
//             children: [
//               {
//                 path: 'menu1-1-1',
//                 name: 'Menu111',
//                 component: () => import('@/views/Level/Menu111.vue'),
//                 meta: {
//                   title: t('router.menu111')
//                 }
//               }
//             ]
//           },
//           {
//             path: 'menu1-2',
//             name: 'Menu12',
//             component: () => import('@/views/Level/Menu12.vue'),
//             meta: {
//               title: t('router.menu12')
//             }
//           }
//         ]
//       },
//       {
//         path: 'menu2',
//         name: 'Menu2',
//         component: () => import('@/views/Level/Menu2.vue'),
//         meta: {
//           title: t('router.menu2')
//         }
//       }
//     ]
//   }
// ]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
